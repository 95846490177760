import React, { useState, useRef } from "react"
import { Link, navigate } from "gatsby"
import { Row, Col, Button, Card, Media, Table, Modal } from 'react-bootstrap'
import Layout from "../components/layout"
import Header from "../components/header"
import SEO from "../components/seo"
import Container from 'react-bootstrap/Container'
import Animation from '../components/animate.js'
import Banner from '../components/banner'
import Common from '../components/common'
import Footer from '../components/footer'
import CommonFlex from '../components/commonFlex'
import Trade from '../components/trade'
import Hexagon from '../components/hexagon'
import Consult from '../components/consult'
import Markdown from "react-markdown"

import { bdstatistics, apiURL, mqStatistics, check } from '../components/census'
import { useStaticQuery, graphql } from "gatsby"

import './../components/media.css'
import './../assets/css/index.css'
import './../assets/css/ecology.css'

// 引入此路径，才不会打包失败
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import './../assets/css/navigation.min.css'
import './../assets/css/pagination.min.css'
import './../assets/css/scrollbar.min.css'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

const data = [
  {
    title: '驱动全面化',
    url: require('./../images/base11.png'),
    img: require('./../images/base1.png'),
    href: '#driver',
  },
  {
    title: '低代码流程图',
    url: require('./../images/base22.png'),
    img: require('./../images/base2.png'),
    href: '#dashboard',
  },
  {
    title: '智能报表',
    url: require('./../images/base33.png'),
    img: require('./../images/base3.png'),
    href: '#report',
  },
  {
    title: '智能报警',
    url: require('./../images/base44.png'),
    img: require('./../images/base4.png'),
    href: '#warning',
  }
]


let partnerData = [
  {
    title: '精工汽车',
    subtitle: '深度赋能离散制造行业',
    description: 'AIRIOT与长城汽车达成长期战略合作，打造智慧工厂智慧车间，促进离散型智能制造技术高速发展。',
    image: require('./../images/精工汽车.png'),
    width: 56,
    height: 48,
    paddingTop: '4rem',
    marginTop: '2.625rem'
  },
  {
    title: '北京环卫集团',
    subtitle: '深度赋能智慧环保行业',
    description: '提供全国标杆性环境问题解决方案，AIRIOT在渗滤液及污水处理等环保领域提供了强大的技术支撑。',
    image: require('./../images/北京环卫集团.png'),
    width: 44,
    height: 55,
    paddingTop: '3.8125rem',
    marginTop: '2.375rem'
  },
  {
    title: '易华录',
    subtitle: '深度赋能大数据行业',
    description: 'AIRIOT为易华录提供了战略性支持，促进企业积极延伸大数据产业价值链，投身区域数字经济基础设施、智慧城市建设。',
    image: require('./../images/毕马威.png'),
    width: 96,
    height: 52,
    paddingTop: '4rem',
    marginTop: '2.375rem'
  },
  {
    title: '羲和能慧',
    subtitle: '深度赋能光伏能源行业',
    description: '开设AIRIOT物联网平台实训基地，并基于AIRIOT搭建光伏发电的能源管理平台，实时监测分析光伏电站的运维管理过程。',
    image: require('./../images/羲和能慧.png'),
    width: 65,
    height: 64,
    paddingTop: '3.5625rem',
    marginTop: '2.0625rem'
  },
  {
    title: '博通水利集团',
    subtitle: '深度赋能智慧环保行业',
    description: '提供全国标杆性环境问题解决方案，AIRIOT在渗滤液及污水处理等环保领域提供了强大的技术支撑。',
    image: require('./../images/博通水利集团.png'),
    width: 80,
    height: 61,
    paddingTop: '3.6875rem',
    marginTop: '2.125rem'
  },
  {
    title: '唐山天地科技',
    subtitle: '深度赋能智能洗煤厂行业',
    description: 'AIRIOT与长城汽车达成长期战略合作，打造智慧工厂智慧车间，促进离散型智能制造技术高速发展。',
    image: require('./../images/唐山天地科技.png'),
    width: 80,
    height: 43,
    paddingTop: '4.1875rem',
    marginTop: '2.75rem'
  },
  {
    title: '西安赛康智能',
    subtitle: '深度赋能智能电力行业',
    description: '提供专业电力检测技术解决方案，AIRIOT高效赋能智能配电业务，搭建完善的电力检测服务保障体系。',
    image: require('./../images/西安赛康智能.png'),
    width: 69,
    height: 50,
    paddingTop: '4.125rem',
    marginTop: '2.375rem'
  },
  {
    title: '中石油',
    subtitle: '深度赋能智慧石油行业',
    description: '联合AIRIOT打造油气生产物联网系统，将油气生产管理、集输储运、生产保障等各场景的数据信息集中在一套平台之上，实现一体化管理。',
    image: require('./../images/中石油.png'),
    width: 64,
    height: 61,
    paddingTop: '3.6875rem',
    marginTop: '2.125rem'
  },
  {
    title: '特变电工',
    subtitle: '深度赋能离散制造行业',
    description: '为全球能源事业提供系统解决方案，AIRIOT为离散制造业的智能工厂提供技术支持，实现更加优质高效的管理效果。',
    image: require('./../images/特变电工.png'),
    width: 142,
    height: 28,
    paddingTop: '5rem',
    marginTop: '2.875rem'
  },
  {
    title: '烟台国工智能',
    subtitle: '深度赋能智能制造行业',
    description: '开设AIRIOT物联网平台实训基地，致力于流程制造业的智能工厂建设，提供食品、医药、精细化工等行业解决方案。',
    image: require('./../images/烟台国工智能.png'),
    width: 67,
    height: 72,
    paddingTop: '3.5625rem',
    marginTop: '1.5625rem'
  },
  {
    title: '西安航天自动化',
    subtitle: '深度赋能智能电力行业',
    description: '基于AIRIOT打造智能配电系统，提供完善的电能一体化管理服务。',
    image: require('./../images/西安航天自动化.png'),
    width: 66,
    height: 65,
    paddingTop: '3.75rem',
    marginTop: '1.8125rem'
  },
  {
    title: '华润',
    subtitle: '深度赋能生物制药行业',
    description: '  持续加大智能药厂升级力度，成功接入AIRIOT物联网平台，提升自动化水准，不断提高品质与效率，树立医药行业智能制造示范标杆。',
    image: require('./../images/华润.png'),
    width: 56,
    height: 55,
    paddingTop: '3.75rem',
    marginTop: '2.4375rem'
  }
]

const IndexPage = () => {
  const [hash, setHash] = React.useState(null)
  const [recomments, setRecomments] = React.useState([])
  const [activeIndex, setActiveIndex] = React.useState(1)
  const [swipers, setSwipers] = React.useState(null)
  const [agent, setAgent] = React.useState(true)
  const [videoBg, setVideoBg] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
  const swiperRef = useRef()
  const videoRef = useRef()
  const [ initPerView, setPerView ] = React.useState(4)

  React.useEffect(() => {
    bdstatistics()
    mqStatistics()

    if (typeof window !== "undefined") {
      if (window.location.hash && window.location.hash.indexOf('#') > -1) {
        setHash(window.location.hash.substr(window.location.hash.indexOf('#') + 1))
      }
    }
    setAgent(check())

  }, [])

  React.useEffect(() => {
    if (hash) {
      // 跳转到指定位置
      let product_media = document.getElementsByClassName('engineHead');
      window.scrollTo({
        top: product_media[0].offsetTop - 150
      })
    }
  }, [hash])

  const getCookie = (name) => {
    var strcookie = document.cookie;//获取cookie字符串
    var arrcookie = strcookie.split("; ");//分割
    //遍历匹配
    for (var i = 0; i < arrcookie.length; i++) {
      var arr = arrcookie[i].split("=");
      if (arr[0] == name) {
        return arr[1];
      }
    }
    return "";
  }

  const downPlat = () => {
    let cookieData = getCookie('data')
    if (cookieData && JSON.parse(cookieData)) {
      let contact_name = JSON.parse(cookieData).contact_name
      navigate("https://r8ja3mlg7i.feishu.cn/share/base/form/shrcnnUgmNSuagV0GKLeIbpG0if")
    } else {
      navigate("https://r8ja3mlg7i.feishu.cn/share/base/form/shrcnnUgmNSuagV0GKLeIbpG0if")
    }
  }

  const data = [
    {
      button_desc: '申请加入AIRIOT生态合作计划',
      button_title: '马上加入',
      url: require('./../images/line-start.png'),
      href: 'https://r8ja3mlg7i.feishu.cn/share/base/form/shrcnnUgmNSuagV0GKLeIbpG0if',
      id: 'start',
      target: true
    },
    {
      button_desc: '下载AIRIOT生态合作伙伴手册',
      button_title: '立即下载',
      url: require('./../images/line-end.png'),
      href: 'http://airiot.cn/strapi/uploads/AIRIOT_1944ce53fb.pdf',
      id: 'start',
      target: true,
      download: true,
      fileName: 'AIRIOT生态合作伙伴计划'
    }
  ]

  return (
    <div className="home-product-sp">
      <div className="home-index home-nav-fix">
        <Header />
      </div>
          <div className="home-bg home-index home-ecology" style={{ position: 'relative', background: `url(${require("./../images/ecology2.png")})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover', height: '34.375rem' }}>
          <Layout typePage="home">
            <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
            <div className="engine-plat">
              <div className="plat-jumbotron">
                  <div className="jumbotron-left">
                      <div className="jumbotron-title">业务生态联盟</div>
                      <div className="jumbotron-desc">面向物联网全行业建立业务生态联盟，强强联合共同收割。</div>
                      <div className="jumbotron-btn-border">
                        <Button variant="primary" className="engine-button" onClick={() => downPlat()}>成为AIRIOT生态合作伙伴</Button>
                      </div>
                  </div>
              </div>
              <div className="jumbotron-right" style={{ height: 0 }}>
                  {/* <img className={`jumbotron-img ${engineObj[name].bannerData.key}`} src={engineObj[name].bannerData.banner}></img> */}
              </div>
            </div> 
          </Layout>
        </div>
      <Consult />
      <Container className="content-box" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <div className="content-height" style={{ position: 'relative', display: 'flex', justifyContent: 'center', width: '100%' }}>
          <div style={{ position: 'absolute', top: 0, height: '6.375rem' }}>
            <CommonFlex animate="animate1" title="面向20+行业及服务领域建立业务生态联盟" desc=""></CommonFlex>
          </div>
          <Row className="scenario-case" style={{ position: 'absolute', zIndex: 1 }}>
            <Col><img className="auth-partner" src={require('./../images/2@2x.png')}/></Col>
          </Row>
        </div>
      </Container>
      <CommonFlex style={{ marginTop: '-4.8125rem' }} animate="animate1" title="业务联盟伙伴（部分）" desc=""></CommonFlex>
      <Container className="content-box" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Row xs={1} lg={3} md={3} className="part-box">
          {
            partnerData.map(item => {
              return <Col style={{ paddingTop: item?.paddingTop }}>
                <span className="part-img" style={{ width: item?.width, height: item?.height, display: 'inline-block' }} />
                <div className="partdata" style={{ marginTop: item?.marginTop }}>{item?.title}</div>
                <div className="part-subtitle">{item?.subtitle}</div>
                <div className="part-description">{item?.description}</div>
              </Col>
            })
          }
        </Row>
      </Container>
      <div>
        <CommonFlex animate="animate1" title="合作模式" desc=""></CommonFlex>
        <div style={{ width: '100%', background: '#FBFCFF' }}>
          <Container className="content-box" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row className="scenario-case">
              <Col><img className="auth-partner" src={require('./../images/合作模式.png')}/></Col>
            </Row>
          </Container>
        </div>
      </div>
      <Container className="content-box" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <CommonFlex animate="animate5" title="开启AIRIOT生态合作之旅" desc=""></CommonFlex>
        <div className="start-air" style={{ marginBottom: '5rem' }}>
          <img src={require('./../images/left-top.png')} className="left-zs" />
          <Common style="30.75rem" shadowFlag={true} data={data}></Common>
        </div>
      </Container>
      <Footer></Footer>
    </div>

  )
}


export default IndexPage
